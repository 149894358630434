<template>
  <div class="container">
    <h3 class="text-center mt-3">Социальный GPA</h3>
    <PrimePreloader v-if="loading"/>
    <div v-if="missingTests.length > 0">Вы не сдали следующие тесты:
      <p style="display: inline-block; margin-right: 5px" v-for="(object, index) in missingTests" :key="index">
        <router-link :to="'/' + object.url">{{ object.name }}</router-link>
      </p>
    </div>
    <div v-else class="social-gpa-main">
      <div class="social-gpa-main__chart-one" v-if="chartData.datasets[0].data.length > 0">
        <Chart type="radar" :data="chartData" :options="chartOption"/>
      </div>
      <div class="social-gpa-main__chart-two" v-if="multiAxisData.datasets[0].data.length > 0">
        <Chart type="bar" :data="multiAxisData" :options="multiAxisOptions "/>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import totalPercent from "../../store/modules/social-gpa/totalPercent";

export default {
  name: "SocialGpa",
  data() {
    return {
      missingTests: [],
      loading: true,
      buttonActive: [],
      chartData: {
        labels: ['Эмоциональная стабильность', 'Открытость', 'Экстраверсия', 'Дружелюбия', 'Осознанность',
                'Внутриличностный ЭИ', 'Межличностный ЭИ', 'Управление стрессом', 'Адаптивность', 'Общее настроение',
                'Шкала самооценки', 'ВК',  'СО', 'Творческое воображение'],
        datasets: [
          {
            label: 'Результаты',
            backgroundColor: 'rgba(13, 137, 236, 0.5)',
            borderColor: 'rgba(13, 137, 236, 0.5)',
            pointBackgroundColor: 'rgba(13, 137, 236, 0.5)',
            pointBorderColor: 'rgba(13, 137, 236, 0.5)',
            pointHoverBackgroundColor: 'rgba(13, 137, 236, 0.5)',
            pointHoverBorderColor: 'rgba(13, 137, 236, 0.5)',
            data: []
          }
        ]
      },
      chartOption: {
        events: [],
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          r: {
            min: 0,
            max: 100,
          }
        }
      },
      multiAxisData: {
        labels: [],
        datasets: [{
          label: 'Результаты',
          backgroundColor: ['#EC407A', '#AB47BC', '#42A5F5', '#7E57C2', '#66BB6A', '#FFCA28', '#26A69A'],
          data: []
        }]
      },
      multiAxisOptions: {
        events: [],
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            min: 0,
            max: 100
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('bigFive', ['GET_BIG_FIVE_RESULTS']),
    ...mapActions('eqiShort', ['GET_EQI_SHORT_ANSWERS']),
    ...mapActions('luscher', ['GET_LUSCHER_ANSWERS']),
    ...mapActions('creativeImaginationTest', ['GET_RESULT']),
    ...mapActions('totalPercent', ['GET_TOTAL_PERCENT']),

    setChart() {
      let scorePercent = 0

      for (let object of this.bigFiveResults) {
        scorePercent = object.score / 14 * 100
        this.chartData.datasets[0].data.push(scorePercent)
      }
      for (let object of this.eqiShortAnswers) {
        scorePercent = object.standart_score / 130 * 100
        console.log(scorePercent)
        this.chartData.datasets[0].data.push(scorePercent)
      }

      scorePercent = this.luscherResults.vk_score / 7 * 100
      this.chartData.datasets[0].data.push(scorePercent)
      scorePercent = this.luscherResults.co_score / 7 * 100
      this.chartData.datasets[0].data.push(scorePercent)
      scorePercent = this.result.score / 17 * 100
      this.chartData.datasets[0].data.push(scorePercent)

      for (let object of this.totalPercent) {
        this.multiAxisData.labels.push(object.competencies_name);
        this.multiAxisData.datasets[0].data.push(object.percent);
      }
    },
  },
  computed: {
    ...mapState('bigFive', ['bigFiveResults']),
    ...mapState('eqiShort', ['eqiShortAnswers']),
    ...mapState('luscher', ['luscherResults']),
    ...mapState('creativeImaginationTest', ['result']),
    ...mapState('totalPercent', ['totalPercent']),
  },
  async mounted() {
    await this.GET_BIG_FIVE_RESULTS();
    if (this.bigFiveResults.length === 0) {
      this.missingTests.push({name: 'Big Five', url: 'big-five'})
    }
    await this.GET_EQI_SHORT_ANSWERS();
    if (this.eqiShortAnswers === false) {
      this.missingTests.push({name: 'Eqi Short', url: 'eqi-short'})
    }
    await this.GET_LUSCHER_ANSWERS();
    if (this.luscherResults === false) {
      this.missingTests.push({name: 'Lusher', url: 'luscher'})
    }
    await this.GET_RESULT();
    if (this.result === null) {
      this.missingTests.push({name: 'Imagination', url: 'creative-imagination-test'})
    }
    if (this.missingTests.length === 0) {
      await this.GET_TOTAL_PERCENT();
      await this.setChart()
      console.log(this.chartData.datasets[0].data)
    }
    this.loading = false
  }
}

</script>

<style scoped>

.social-gpa-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-gpa-main__chart-one {
  width: 40%;
  height: 40%;
}

.social-gpa-main__chart-two {
  width: 60%;
  height: 60%;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .social-gpa-main {
    flex-direction: column;
  }

  .social-gpa-main__chart-one, .social-gpa-main__chart-two {
    width: 100%;
    height: 120%;
  }
}
</style>
